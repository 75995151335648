import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import TradeSecretsDisclaimer from "../components/TradeSecretsDisclaimer"

const TemplatePage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Software Development Engineering Intern - Facebook</h1>
            <sub><em>Now Meta!</em></sub>
            {TradeSecretsDisclaimer()}
            <p>What can I say that won't get me in trouble...</p>
            <p>I worked for summer 2020 and summer 2021 at Facebook (remote). I worked on two different teams that got merged into one team on my last day of the second summer. These teams were broadly responsible for Network Analytics (monitoring and alerting style activities). I worked in a combination of C++ and Python, one language used for the programs that ran on every network switch, router, and server and the other language for stuff that ran a couple times a day. I'll let you guess which is which. Over the the summers, I worked on three projects described at a high level in the next couple paragraphs.</p>
            <p>There was a mapping of <em>datatype</em> to metadata. It was fourteen GB of ram. It refreshed very slowly so what ended up happening was one map was used to serve requests while another map was refreshed in the background. These two maps were atomic swapped at some point so that the data being served was fresh and the other could update. This library ran on quite a few machines and handled alot of requests per day; maintaining the overhead of an entire extra fourteen GB map was troublesome, especially on some machines that had only 32 GB of ram (four of which was reserved for the operating system: 4 + 14 + 14 = 32), I re-wrote the refresh process using hashing and sharding. This allowed for one shard to be refreshed at a time significantly reducing overhead and slightly increasing data freshness.</p>
            <p>The Network Analytics team is tracking every statistic you can think of and several that you probably wouldn't think of. However sometimes things go wrong. Whats worse is when things go wrong without raising an alert. I developed a tool to monitor two data streams that should match and report when they didn't. Of course, the two data sources used different backends and had different rounding policies (imagine how quickly a Facebook router goes from processing no data to alot of data, a slight difference in rounding makes a huge difference in output). In theory, these two data streams always (nearly) matched. In practice they sometimes did not. In these cases, I raised an alert with the two teams responsible and let them figure out where the problem was.</p>
            <p>There are all kinds of tools in the Facebook tech stack, a big chunk of them are testing tools. In my second summer, I wrote a tool for testing the network tester. The network team had an allocation of test machines that they could allocate how they see fit. Likewise there were tools that simulated various network traffic patterns. I wrote a utility that would spin up test machines, configure the traffic pattern, run the tests, save the results, and tear down the environment. I also would interface with services like <a href="https://engineering.fb.com/2020/12/09/data-center-engineering/how-facebook-keeps-its-large-scale-infrastructure-hardware-up-and-running/">FBAR</a> to keep the test machines at peak performance.</p>
            <p>In case a former (or current) Facebook employee is curious, I worked mostly with:</p>
            <ul>
                <li><a href="https://engineering.fb.com/2021/06/21/data-infrastructure/tectonic-file-system/">Manifold DB - a key value database</a></li>
                <li>ODS</li>
                <li><a href="https://research.facebook.com/publications/scuba-diving-into-data-at-facebook/#:~:text=Scuba%20is%20a%20fast%2C%20scalable,each%20with%20144%20GB%20RAM.&text=Scuba%20processes%20almost%20a%20million%20queries%20per%20day.">Scuba</a></li>
                <li><a href="https://engineering.fb.com/2019/06/06/data-center-engineering/twine/">Tupperware</a></li>
            </ul>

            {FooterBar()}
        </div>
    )
}

export default TemplatePage