import DisclaimerBase from "./DisclaimerBase"

function TradeSecretsDisclaimer(prop) {
    return (
        DisclaimerBase(
            {
                s: "Trade Secrets Disclaimer",
                p: "A through discussion would reveal stuff that at minimum shouldn't be public and at worst would get me in legal trouble. For this reason, things are intentionally left somewhat vague."
            }
        )
    )
}

export default TradeSecretsDisclaimer